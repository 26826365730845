import React, {useEffect, useState} from "react";
import { useMap, MapboxGeoJSONFeature } from "react-map-gl";

import {times} from "lodash";

import { Box, Typography, Card, CardContent, Stack, FormControl, InputLabel } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { useTheme, lighten } from "@mui/material/styles";

import { toPercentage } from "@/utils/common";
import {InsightLocationResult} from "@/types";
import { timeOfDay, modeOfTransport } from "@/constants"

type SimpleOverlayProps = {
  locations: InsightLocationResult[];
  breaks: number[];
  selected?: MapboxGeoJSONFeature;
};

const SimpleOverlay: React.FC<SimpleOverlayProps> = ({ locations, breaks, selected }) => {


  const { current: map } = useMap();
  const theme = useTheme();
  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [areaScores, setAreaScores] = useState<InsightLocationResult[]>([]);
  const [districtScores, setDistrictScores] = useState<InsightLocationResult[]>([]);
  const [sectorScores, setSectorScores] = useState<InsightLocationResult[]>([]);

  const [timePeriods, setTimePeriods] = useState<number[]>([]);
  const [transportModes, setTransportModes] = useState<number[]>([]);

  useEffect(() => {
    if (timePeriods.length > 0 && transportModes.length > 0) {
      setAreaScores(applyFilter(locations.filter((x) => x.tag_group === "Postal Area"), timePeriods, transportModes));
      setDistrictScores(applyFilter(locations.filter((x) => x.tag_group === "Postal District"), timePeriods, transportModes));
      setSectorScores(applyFilter(locations.filter((x) => x.tag_group === "Postal Sector"), timePeriods, transportModes));
    } else if (timePeriods.length > 0) {
      setAreaScores(applyFilter(locations.filter((x) => x.tag_group === "Postal Area"), timePeriods, [0]));
      setDistrictScores(applyFilter(locations.filter((x) => x.tag_group === "Postal District"), timePeriods, [0]));
      setSectorScores(applyFilter(locations.filter((x) => x.tag_group === "Postal Sector"), timePeriods, [0]));
    } else if (transportModes.length > 0) {
      setAreaScores(applyFilter(locations.filter((x) => x.tag_group === "Postal Area"), [0], transportModes));
      setDistrictScores(applyFilter(locations.filter((x) => x.tag_group === "Postal District"), [0], transportModes));
      setSectorScores(applyFilter(locations.filter((x) => x.tag_group === "Postal Sector"), [0], transportModes));
    } else {
      setAreaScores(locations.filter((x) => x.tag_group === "Postal Area"));
      setDistrictScores(locations.filter((x) => x.tag_group === "Postal District"));
      setSectorScores(locations.filter((x) => x.tag_group === "Postal Sector"));
    }

  }, [locations, timePeriods, transportModes]);

  const applyFilter = (data: InsightLocationResult[], tod: number[], mod: number[]) => {
    return data.filter((row) => (
      row.filters.filter(x => tod.includes(x.tod)).filter(x => mod.includes(x.mod)).map(x => x.value).some(x => x === 1)
    ))
  }

  useEffect(() => {
    map && map.isStyleLoaded() && !firstTime && resetMap();
    map && map.isStyleLoaded() &&
    areaScores.forEach((value) => {
      map.setFeatureState(
        {
          id: value.tag_id - 113,
          source: "area-source",
          sourceLayer: "postal_area_test",
        },
        { score: value.score_sum, percentage: value.score, name: value.tag_name, level: value.tag_group }
      );
    });
    map && map.isStyleLoaded() &&
    districtScores.forEach((value) => {
      map.setFeatureState(
        {
          id: value.tag_id - 237,
          source: "district-source",
          sourceLayer: "postal_district_test",
        },
        { score: value.score_sum, percentage: value.score, name: value.tag_name, level: value.tag_group }
      );
    });
    map && map.isStyleLoaded() &&
    sectorScores.forEach((value) => {
      map.setFeatureState(
        {
          id: value.tag_id - 3076,
          source: "sector-source",
          sourceLayer: "postal_sector_test",
        },
        { score: value.score_sum, percentage: value.score, name: value.tag_name, level: value.tag_group }
      );
    });
    setFirstTime(false);

  }, [map, areaScores, districtScores, sectorScores]);

  const resetMap = () => {
    map && [...times(121)].forEach(value => {
      map.setFeatureState(
        {id: value, source: 'area-source', sourceLayer: 'postal_area_test'},
        {score: null, percentage: null, name: null, level: null}
      );
    });
    map && [...times(2816)].forEach(value => {
      map.setFeatureState(
        {id: value, source: 'district-source', sourceLayer: 'postal_district_test'},
        {score: null, percentage: null, name: null, level: null}
      );
    });
    map && [...times(9469)].forEach(value => {
      map.setFeatureState(
        {id: value, source: 'sector-source', sourceLayer: 'postal_sector_test'},
        {score: null, percentage: null, name: null, level: null}
      );
    });
  };

  const handleTimePeriodChange = (event: SelectChangeEvent<number[]>) => {
    const {target: { value }} = event;
    setTimePeriods(typeof value === 'string' ? value.split(',').map(x => Number(x)) : value);
    if (value.length === 0) setTransportModes([]);
  }

  const handleTransportModeChange = (event: SelectChangeEvent<number[]>) => {
    const {target: { value }} = event;
    setTransportModes(typeof value === 'string' ? value.split(',').map(x => Number(x)) : value);
  }

  const renderTimePeriods = (selected: number[]) => {
    if (selected.length > 1) {
      return `${selected.length} Selected`
    } else {
      return timeOfDay.filter(x => x.value === selected[0])[0].name;
    }
  }

  const renderTransportModes = (selected: number[]) => {
    if (selected.length > 1) {
      return `${selected.length} Selected`
    } else {
      return modeOfTransport.filter(x => x.value === selected[0])[0].name;
    }
  }


  return (
    <>
      <Box sx={{ position: "relative" }}>
        {selected && Object.keys(selected.state).length > 0 && (
          <Card sx={{ position: "absolute", top: 12, right: 12 }}>
            <CardContent
              sx={{ p: 2, "&:last-child": { pb: 2 }, minWidth: "150px" }}
            >
              <Typography>{`${selected?.state.level}: ${selected?.state.name}`}</Typography>
              <Typography variant="body2">{`Penetration: ${toPercentage(
                selected?.state.percentage
              )}`}</Typography>
            </CardContent>
          </Card>
        )}
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 20,
          left: 12,
          width: '200px',
        }}
      >
        <Stack direction="column" spacing={1}>
          <Typography variant="body1" sx={{pl: 1}}>Movement Filters</Typography>
          <FormControl variant="filled">
            <InputLabel id="time-period-label">Time Period</InputLabel>
            <Select
              label="Time Period"
              labelId="time-period-label"
              value={timePeriods}
              onChange={handleTimePeriodChange}
              renderValue={renderTimePeriods}
              multiple
              sx={{
                minWidth: 160,
                backgroundColor: theme.palette.background.default,
                "&:hover": {
                  backgroundColor: lighten(theme.palette.background.default, 0.1),
                },
                "&.Mui-focused": {
                  backgroundColor: lighten(theme.palette.background.default, 0.1),
                }
              }}
            >
              {timeOfDay.map((timePeriod) => (
                <MenuItem key={timePeriod.name} value={timePeriod.value}>
                  <Checkbox checked={timePeriods.includes(timePeriod.value)} />
                  <ListItemText primary={timePeriod.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {timePeriods.length > 0 &&
            <FormControl variant="filled">
              <InputLabel id="transport-mode-label">Transport Mode</InputLabel>
              <Select
                label="Transport Mode"
                labelId="transport-mode-label"
                value={transportModes}
                onChange={handleTransportModeChange}
                // renderValue={(selected: number[]) => {selected.length === 1 ? modeOfTransport.filter(x => x.value === [selected[0]])[0].name : `${selected.length} Selected`}
                renderValue={renderTransportModes}
                multiple
                sx={{
                  minWidth: 160,
                  backgroundColor: theme.palette.background.default,
                  "&:hover": {
                    backgroundColor: lighten(theme.palette.background.default, 0.1),
                  },
                  "&.Mui-focused": {
                    backgroundColor: lighten(theme.palette.background.default, 0.1),
                  }
                }}
              >
                {modeOfTransport.map((transportMode) => (
                  <MenuItem key={transportMode.name} value={transportMode.value}>
                    <Checkbox checked={transportModes.includes(transportMode.value)} />
                    <ListItemText primary={transportMode.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
        </Stack>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 40,
          left: 12,
          width: '150px',
        }}
      >
        <Box sx={{
          width: '150px',
          height: '10px',
          borderRadius: '10px',
          backgroundImage: 'linear-gradient(to right, #f7d7ff, #d5a5e6, #8b45b6, #61089f)'
        }}/>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant='caption'>{toPercentage(breaks[0], 0)}</Typography>
          <Typography variant='caption'>{toPercentage(breaks[1], 0)}</Typography>
          <Typography variant='caption'>{toPercentage(breaks[2], 0)}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default SimpleOverlay;
