import React from "react";

import {Box, Button, Grid} from "@mui/material";

import {InsightResult} from "@/types";
import { MediaCard } from "./MediaCard";
import { MediaGraphCard } from "./MediaGraphCard";
import { ScoreCard } from "./ScoreCard";
import { ScoreGraphCard } from "./ScoreGraphCard";
import DownloadIcon from "@mui/icons-material/Download";
import {downloadScores} from "@/utils/download";

interface MediaTabProps {
  data: InsightResult[];
}

const MediaTab: React.FC<MediaTabProps> = ({ data }) => {

  const mediaTypeData = data.filter(v => v.tag_group == 'Media Type');
  const mediaTypes = Array.from(new Set(mediaTypeData.map((v) => v.tag_category)));

  const mediaBrandData = data.filter(
    v => v.tag_category == 'Media Subscriptions'
  )

  const handleDownload = () => {
    downloadScores('Media', data);
  }

  return (
    <>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleDownload}
            startIcon={<DownloadIcon />}
            sx={{ mb: "2px"}}
          >
            Download
          </Button>
      </Grid>
      <Grid item xs={6}>
        <MediaCard name={'Media Type'} data={mediaTypeData} groups={mediaTypes} />
      </Grid>
      <Grid item xs={6}>
        <ScoreCard
          name={'Media Subscriptions'}
          data={mediaBrandData}
        />
      </Grid>
      <Grid item xs={6}>
        <MediaGraphCard name={'Media Type'} data={mediaTypeData} groups={mediaTypes} />
      </Grid>
      <Grid item xs={6}>
        <ScoreGraphCard name={'Media Subscriptions'} data={mediaBrandData} />
      </Grid>
    </>

  )

};

export default MediaTab;
