import React, {useState, useEffect} from "react";
import {
  Typography,
  Grid,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Tooltip,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Stack
} from "@mui/material";
import SortIcon from '@mui/icons-material/Sort';
import DownloadIcon from '@mui/icons-material/Download';

import { sortBy } from "lodash";

import {InsightResult, Tag} from "@/types";
import {toIndex, toPercentage} from "@/utils/common";
import { downloadScores } from "@/utils/download";


interface ScoreTabProps {
  data: InsightResult[];
}

const TopScoreTab: React.FC<ScoreTabProps> = ({ data }) => {

  const [scores, setScores] = useState<InsightResult[]>([]);
  const [scoreView, setScoreView] = useState<string>("score");
  const [sortType, setSortType] = useState<string>("desc");

  useEffect(() => {
    sortType === 'asc'
      ? setScores(sortBy(data.filter(x => x.rate < 1).filter(x => x.rate > 0.01), scoreView).slice(0, 50))
      : setScores(sortBy(data.filter(x => x.rate < 1).filter(x => x.rate > 0.01), scoreView).reverse().slice(0, 50))
  }, [data, scoreView, sortType])

  const handleScoreViewChange = (_e: React.MouseEvent<HTMLElement>, newVal: string | null) => {
    if (newVal !== null) setScoreView(newVal);
  };

  const handleSortTypeChange = (_e: React.MouseEvent<HTMLElement>, newVal: string | null) => {
    if (newVal !== null) setSortType(newVal);
  };

  const handleDownload = () => {
    downloadScores('Top Scores', scores);
  }

  return (
    <>
    <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
      <Stack direction="row" spacing={3} alignItems="flex-end">
        <Box>
          <Typography variant="overline">Sort By</Typography>
          <div>
            <ToggleButtonGroup
              value={scoreView}
              onChange={handleScoreViewChange}
              exclusive
              size="small"
              aria-label="score type change"
            >
              <ToggleButton value="zscore" aria-label="zscore">
                <Tooltip title="Score" placement="left">
                  <>Score</>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="score" aria-label="score">
                <Tooltip title="Index" placement="right">
                  <>Index</>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="rate" aria-label="rate">
                <Tooltip title="Penetration" placement="right">
                  <>% Pen</>
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Box>
        <Box>
          <Typography variant="overline">Sort Direction</Typography>
          <div>
            <div>
              <ToggleButtonGroup
                value={sortType}
                onChange={handleSortTypeChange}
                exclusive
                size="small"
              >
                <ToggleButton value="asc" aria-label="asc">
                  <SortIcon sx={{ transform: 'scaleY(-1)'}}/>
                </ToggleButton>
                <ToggleButton value="desc" aria-label="desc">
                  <SortIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </Box>
        <Box>
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleDownload}
            startIcon={<DownloadIcon />}
            sx={{ mb: "2px"}}
          >
            Download
          </Button>
        </Box>
      </Stack>
    </Grid>
      {scores.map((variable, idx) => (
        <Grid item key={idx}>
          <Card elevation={8} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: 246,
            height: 176
          }}>
            <CardHeader
              disableTypography
              title={
                <Stack alignItems="flex-start">
                  {/*<Chip size="small" label={variable.tag_group}/>*/}
                  <Typography variant="body2" color="textSecondary">
                    {variable.tag_group}
                  </Typography>
                  <Typography variant="body1">
                    {variable.tag_category}
                  </Typography>
                </Stack>
              }
              sx={{pb: 0}}
            />
            <CardContent>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
                <Typography variant="h6" color="primary" sx={{maxWidth: 180}}>{variable.tag_name}</Typography>
                {scoreView === 'score' &&
                  <Typography variant="caption" color="primary">{toIndex(variable.score)}</Typography>
                }
                {scoreView === 'zscore' &&
                  <Typography variant="caption" color="primary">{toIndex(variable.zscore || 0)}</Typography>
                }
                {scoreView === 'rate' &&
                  <Typography variant="caption" color="primary">{toPercentage(variable.rate, 1)}</Typography>
                }

              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </>
  )
};

export default TopScoreTab;
