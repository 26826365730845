
import React, {useState, useEffect} from "react";
import {
  Typography,
  Grid,
  TextField,
  MenuItem,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Stack, Button
} from "@mui/material";
import { BarChart, TableChart } from "@mui/icons-material";

import {InsightResult, Tag} from "@/types";
import useStyles from "./ScoreTab.styles";
import {GraphView, TableView} from "@/components/molecules";
import {tabGroups} from "@/constants";
import {downloadScores} from "@/utils/download";
import DownloadIcon from "@mui/icons-material/Download";


interface ScoreTabProps {
  tab: string;
  data: InsightResult[];
  tags: Array<Tag>;
}

const ScoreTab: React.FC<ScoreTabProps> = ({ tab, data, tags }) => {

  const styles = useStyles();

  const [group, setGroup] = useState<string>('');
  const [groupData, setGroupData] = useState<InsightResult[]>([]);
  const [groupCategories, setGroupCategories] = useState<string[]>([]);
  const [view, setView] = useState<string>("graph");
  const [scoreView, setScoreView] = useState<string>("index");

  useEffect(() => {
    setGroup(tabGroups[tab]);
  }, [tab])

  useEffect(() => {
    const tmpData = data.filter((x) => x.tag_group === group);
    setGroupData(tmpData);
    setGroupCategories(Array.from(new Set(tmpData.map((v) => v.tag_category))));
  }, [data, group])

  const tagGroups = Array.from(new Set(tags.filter(val => val.tag_type === tab).map(val => val.tag_group)));

  const handleGroupSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroup(e.target.value);
  }

  const handleViewChange = (_e: React.MouseEvent<HTMLElement>, newVal: string | null) => {
    if (newVal !== null) setView(newVal);
  };

  const handleScoreViewChange = (_e: React.MouseEvent<HTMLElement>, newVal: string | null) => {
    if (newVal !== null) setScoreView(newVal);
  };

  const handleDownload = () => {
    downloadScores(tab, data);
  }

  return (
    <>
    <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
      <TextField
        id="group-select"
        label="Choose a Group"
        variant="outlined"
        select
        value={group}
        onChange={handleGroupSelect}
        InputProps={{ className: styles.textBox, disableUnderline: true }}
      >
        {tagGroups.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <Stack direction="row" spacing={3} alignItems="flex-end">
        {view === "graph" && (
          <Box>
            <Typography variant="overline">Score Type</Typography>
            <div>
              <ToggleButtonGroup
                value={scoreView}
                onChange={handleScoreViewChange}
                exclusive
                size="small"
                aria-label="score type change"
              >
                <ToggleButton value="score" aria-label="score">
                  <Tooltip title="Score" placement="left">
                    <>Score</>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="index" aria-label="index">
                  <Tooltip title="Index" placement="right">
                    <>Index</>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Box>
          )}
          <Box>
            <Typography variant="overline">View Type</Typography>
            <div>
              <ToggleButtonGroup
                value={view}
                onChange={handleViewChange}
                exclusive
                size="small"
                aria-label="view change"
              >
                <ToggleButton value="table" aria-label="table">
                  <Tooltip title="Table view" placement="left">
                    <TableChart />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="graph" aria-label="graph">
                  <Tooltip title="Graph view" placement="right">
                    <BarChart />
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Box>
          <Box>
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleDownload}
              startIcon={<DownloadIcon />}
              sx={{ mb: "2px"}}
            >
              Download
            </Button>
          </Box>
        </Stack>
      </Grid>
      {view === 'graph' ?
        <GraphView
          tab={tab}
          data={groupData}
          categories={groupCategories}
          scoreView={scoreView}
        />
      :
        <TableView data={groupData} groups={groupCategories} category={tab} />
      }
    </>
  )
};

export default ScoreTab;
