import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  Chip,
  Grid,
  ListSubheader,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { createFilterOptions } from '@mui/material/Autocomplete';

import { Tag } from "@/types";

import useStyles from "./VariableSelection.styles";

type TagGroup = {
  tag_type: string,
  tag_group: string
}

interface VariableSelectionProps {
  groupId: number;
  categories: Array<string>;
  groupSelection: Array<Tag>;
  tags: Array<Tag>;
  onSelectionUpdate: (groupId: number, selection: any[]) => void;
}

const VariableSelection: React.FC<VariableSelectionProps> = ({
  groupId,
  categories,
  tags,
  groupSelection,
  onSelectionUpdate,
}) => {
  const styles = useStyles();

  const [group, setGroup] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [selection, setSelection] = useState<Tag[]>([]);

  const tagGroups = Array.from(new Set(tags.map(val => val.tag_type)));
  const tagCategories = Array.from(new Set(tags.filter(val => val.tag_type === group).map(val => val.tag_group)));
  const tagOptions = tags.filter(val => val.tag_type === group).filter(x => x.tag_group === category);

  useEffect(() => {
    setSelection(groupSelection);
  }, [groupSelection, tags]);

  const handleGroupSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategory('');
    setSelection([]);
    setGroup(e.target.value);
  }

  const handleCategorySelect = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCategory(e.target.value);

  const handleSelectionUpdate = (_e: React.SyntheticEvent, selection: any) =>
    onSelectionUpdate(groupId, selection);

  const handleDelete = (tagId: number) => {
    const newSelection = selection.filter((v) => v.tag_id !== tagId);
    onSelectionUpdate(groupId, newSelection);
    setSelection(newSelection);
  };

  const filterOptions = createFilterOptions({
    stringify: (option: Tag) => `${option.tag_category} ${option.tag_name}`
  });

  const renderGroup = (params: AutocompleteRenderGroupParams) => [
    <ListSubheader
      key={params.key}
      component="div"
      disableSticky
      className={styles.groupHeader}
    >
      {params.group}
    </ListSubheader>,
    params.children,
  ];

  return (
    <>
      <Grid item xs={3}>
        <TextField
          fullWidth
          id="group-select"
          label="Group"
          variant="filled"
          select
          value={group}
          onChange={handleGroupSelect}
          InputProps={{ className: styles.textBox, disableUnderline: true }}
        >
          {tagGroups.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          id="category-select"
          label="Category"
          variant="filled"
          select
          value={category}
          onChange={handleCategorySelect}
          InputProps={{ className: styles.textBox, disableUnderline: true }}
        >
          {tagCategories.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          id="selection-box"
          multiple
          options={tagOptions}
          disableCloseOnSelect
          value={selection}
          onChange={handleSelectionUpdate}
          groupBy={(option) => option.tag_category}
          getOptionKey={(option) => option.tag_id}
          getOptionLabel={(option) => option.tag_name}
          getOptionDisabled={() => selection.length === 10}
          renderGroup={renderGroup}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Variable"
              variant="filled"
              placeholder="Select Variables"
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                style: { borderRadius: "4px" },
                disableUnderline: true,
              }}
            />
          )}
          renderTags={() => null}
          filterOptions={filterOptions}
          classes={{ option: styles.listOption }}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0} className={styles.selectionPanel}>
          <div className={styles.rowDiv}>
            <Typography variant="body2" className={styles.selectionText}>
              Selection
            </Typography>
            <Typography variant="body2" className={styles.selectionCount}>
              ({selection.length}/10)
            </Typography>
          </div>
          {selection.map((v) => (
            <Chip
              className={styles.chip}
              key={v.tag_id}
              label={`${v.tag_group} | ${v.tag_category} | ${v.tag_name}`}
              onDelete={() => handleDelete(v.tag_id)}
              size="small"
              color="primary"
            />
          ))}
        </Paper>
      </Grid>
    </>
  );
};

export default VariableSelection;
