import { CsvBuilder } from 'filefy';
import { InsightResult } from "@/types";
import {toIndex, toPercentage} from "./common";


export const downloadScores = (tab: string, data: InsightResult[]) => {
  let today = new Date().toJSON().slice(0,10);
  const rows = data.map(x => [
    x.tag_type,
    x.tag_group,
    x.tag_category,
    x.tag_name,
    toPercentage(x.rate, 2),
    toIndex(x.score),
    x.zscore ? toIndex(x.zscore) : ''
  ]);
  const builder = new CsvBuilder(`${tab} - ${today}.csv`);
  builder
    .setDelimeter(',')
    .setColumns(['Type', 'Group', 'Category', 'Name', 'Penetration', 'Index', 'Z-Score'])
    .addRows(rows)
    .exportFile();
};
